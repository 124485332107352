import { StoreState } from '@common/types/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import getTimezoneOffset from 'get-timezone-offset';
import { MINUTE } from '@common/definitions';

const MINUTES_MS = 1000 * MINUTE;

export const useUserTimezone = () => {
  return useSelector((state: StoreState) => state.loggedUser.user.time_zone);
};

export const useTimezonesData = () => {
  // we only use minutes as a dynamic dependency of useMemo, so the timezones
  // data gets recomputed at max once a minute (the only data that can change
  // is the offset, as some timezones might enter legal or solar offsets from
  // one minute to another)
  const minutesFrom1970 = Math.floor(Date.now() / MINUTES_MS);
  return useMemo(() => {
    const timezones = Intl?.supportedValuesOf('timeZone');
    if (!timezones) return null;

    const date = new Date(minutesFrom1970 * MINUTES_MS);

    return timezones.map((timezone: string) => {
      return {
        name: timezone,
        offset: getTimezoneOffset(timezone, date) as number
      };
    });
  }, [minutesFrom1970]);
};

export const getOffsetFromTimezone = (timezone: string) => {
  return getTimezoneOffset(timezone, new Date()) * 60 * -1;
};

export const useTimezone = () => {
  const userTz = useUserTimezone();
  const browserTz = Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone;
  return browserTz || userTz;
};
