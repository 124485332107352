import React, { memo } from 'react';
import SearchBar from '@common/components/search-bar';
import PredicateFiltersGroup from './predicate-filters-group';

const FiltersToolbar = memo(({
  predicates,
  setPredicates,
  organisation,
  searchEnabled = true,
  onSearch,
  searchPlaceholder,
  debounce,
  children
}: any) => {
  return (
    <div className="PredicatesFilter__Filters">
      {
        searchEnabled ?
          <SearchBar
            onSearch={onSearch}
            debounce={debounce}
            placeholder={searchPlaceholder}
          /> :
          null
      }
      <PredicateFiltersGroup
        predicates={predicates}
        setPredicates={setPredicates}
        organisation={organisation}
      />
      { children }
    </div>
  );
});

export default FiltersToolbar;
