import * as React from 'react';
import * as R from 'ramda';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import Group from '@common/components/form/group';
import Label from '@common/components/form/label';
import Row from '@common/components/form/row';
import TextInput from '@common/components/form/inputs/text';
import FileInput from '@common/components/form/inputs/file';
import MarkupEditor from '@common/components/form/inputs/markup';
import { formatComponent } from '@modules/learning/reducers/draft';

import i18n from '../../../../i18n';
import { Button } from '../../../../common/components/button';
import Confirm from '../../../../common/components/confirm-button';
import Attachment from '../../../../common/components/attachment';
import * as draftSelector from '../../selectors/draft';
import { EComponentTypes } from '../../definitions';

const ETypes = {
  [EComponentTypes.TEXT]: [{
    name: 'parameters.text',
    component: (props) => <MarkupEditor dispatchUpdatesOnChange {...props} />,
    placeholder: i18n.t('learning:form_component_text_placeholder'),
  }],
  [EComponentTypes.IMAGE]: [{
    name: 'attachment',
    hideInput: true,
    preview: ({ component }) => (
      <Group>
        <Attachment
          preview
          item={component.parameters.attachment}
          maxHeight={250}
        />
      </Group>
    ),
    component: FileInput,
    accept: 'image/jpg,image/jpeg,image/png,image/gif',
    dragndrop: true,
    processFile: true,
    unsplash: true,
  }],
  [EComponentTypes.VIDEO]: [{
    label: i18n.t('learning:form_component_add_video'),
    name: 'attachment',
    preview: ({ component }) => ( // eslint-disable-line react/prop-types
      <Group>
        <Label text={i18n.t('learning:form_component_label_current_video')} />
        <Attachment
          preview
          item={component.parameters.attachment}
          maxHeight={250}
        />
      </Group>
    ),
    component: FileInput,
    accept: 'video/mp4,video/*',
    maxFileSize: 500,
    dragndrop: true,
    processFile: true,
  }],
  [EComponentTypes.PDF]: [{
    label: i18n.t('learning:form_component_add_file'),
    name: 'attachment',
    component: FileInput,
    accept: 'application/pdf',
    maxFileSize: 10,
    dragndrop: true,
    processFile: true,
  }],
  [EComponentTypes.EXTERNAL_VIDEO]: [{
    label: i18n.t('learning:form_component_link_to_youtube_video'),
    name: 'youtube_url',
    placeholder: 'https://',
    autoFocus: true,
    component: TextInput,
    preview: ({ component }) => ( // eslint-disable-line react/prop-types
      <Group>
        <Label text={i18n.t('learning:form_component_label_current_youtube_video')} />
        <Attachment
          preview
          item={component.parameters.attachment}
          maxHeight={250}
        />
      </Group>
    ),
  }],
};

class ComponentForm extends React.Component {
  constructor() {
    super();

    this.handleDelete = this.handleDelete.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { item, initialize } = this.props;

    if (item) initialize(item);
  }

  static props;

  handleDelete() {
    const { item, onDelete, onClose } = this.props;

    if (item.id) onDelete(item.id);

    onClose();
  }

  getInputFields() {
    const { item, disabled } = this.props;

    return (ETypes[item.type] || []).map((input) => {
      const {
        component: InputComponent, preview: PreviewComponent, name, label, ...props
      } = input;

      return (
        <React.Fragment key={name}>
          {!(item && PreviewComponent && !R.isEmpty(item.parameters)) && (
            <Row>
              <Group>
                {label && <Label text={label} />}
                <InputComponent name={name} disabled={disabled} {...props} />
              </Group>
            </Row>
          )}
          {item && PreviewComponent && !R.isEmpty(item.parameters) && (
            <Row>
              <PreviewComponent component={item} />
            </Row>
          )}
        </React.Fragment>
      );
    });
  }

  render() {
    const { children, invalid, onClose, t } = this.props;

    if (!this.props.item) return null;

    return (
      <form className="Form Form--horizontal">
        <Row>
          {children}
          <Confirm
            title={t('learning:form_component_confirm_remove')}
            onConfirm={this.handleDelete}
          >
            <Button type="transparent" size="large"><Trans i18nKey="learning:form_component_remove" /></Button>
          </Confirm>
          <Button
            type="inverted-primary"
            size="large"
            disabled={invalid}
            onClick={onClose}
          >
            <Trans i18nKey="common:close" />
          </Button>
        </Row>
        {this.getInputFields()}
      </form>
    );
  }
}

const mapStateToProps = () => {
  const itemSelector = draftSelector.component();
  const feedbackSelector = draftSelector.componentFeedback();

  // Removed the depency on the redux draft state by falling back on props.item
  // when no draft state is found.
  // Should be removed in scope of ONE-807
  return (state, props) => ({
    item: props.item.id ?
      itemSelector(state, props.item) || feedbackSelector(state, props.questionId, props.item) || props.item :
      props.item,
  });
};

export default withTranslation()(connect(mapStateToProps)(reduxForm({
  form: 'component',
  initialValues: { type: null },
  onChange: (newValue, _, { screenId, item, updateComponent }) => {
    if (updateComponent && screenId) {
      const field = ETypes[newValue.type][0].name;
      const payload = get(newValue, field);
      if (payload) {
        const updatedComponent = formatComponent(item, {
          payload,
          meta: { field },
        });
        updateComponent(screenId, updatedComponent);
      }
    }
  },
  validate: require('../validators/component').default,
})(ComponentForm)));
