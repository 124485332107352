import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@common/components/icon';
import SimpleText from '@common/components/simple-text';

import './hint.scss';

type HintProps = {
  text: string;
};

const Hint = memo(({ text }: HintProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <div className="Hint">
      <div className="Hint__Toggle">
        <Icon type="lightbulb" size="small" />
        <p onClick={() => setOpen(!open)} className="Hint__ToggleText">
          { t(open ? 'learning:hide_hint' : 'learning:show_hint') }
        </p>
      </div>
      {
        open && text && <SimpleText text={text} />
      }
    </div>
  );
});

export default Hint;
