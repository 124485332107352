import * as R from 'ramda';
import createTempId from '../../../common/utils/create-temp-id';

export const ACADEMY_ADD_COMPONENT = 'academy/ADD_COMPONENT';

const whitelist = ['text'];

export default (screenId, contentType, data) => async (dispatch) => {
  const { type, index, ...parameters } = data;
  const payload = { type, index, parameters: R.pick(whitelist, parameters) };

  return dispatch({
    type: ACADEMY_ADD_COMPONENT,
    screenId,
    contentType,
    component: {
      id: createTempId(),
      local: true,
      ...payload,
    },
  });
};
