import * as React from 'react';
import Markdown from 'react-markdown';

const ComponentText = ({ item }) => (
  <div className="Screen__Component__Text">
    <Markdown>{item.parameters.text?.replaceAll('\n', '&nbsp;\n\n')}</Markdown>
  </div>
);

export default ComponentText;
