import * as React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

import Icon from '@common/components/icon';
import addComponentAction from '../../actions/add-component';
import { EComponentTypes } from '../../definitions';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';

type Props = {
  id: string;
  type: string;
  packageId?: typeof EPlanPackageConfig[keyof typeof EPlanPackageConfig],
  components: any[];
  addComponentProp: any;
  addComponent?: any;
};

const AddComponentForm = ({
  id, components, addComponent, addComponentProp, type, packageId,
}: Props) => {
  const { isAvailable: canAddModule, showUpgradeModal } = useIsAvailableInPlanPackage(packageId);

  const handleAdd = async (contentType: typeof EComponentTypes[keyof typeof EComponentTypes]) => {
    if (!id) return;

    if (!canAddModule) {
      showUpgradeModal();
      return;
    }

    const maxIndex = +R.reduce(R.max, -1, R.pluck('index', components));
    if (addComponent) {
      return addComponent({
        id,
        component: { type: contentType, index: maxIndex + 1, parameters: {} },
      });
    }
    // When no addCompoent handler is provided fallback to
    // addComponentProp action that will add the component to the redux draft state.
    // Should be removed in scope of ONE-807
    await addComponentProp(id, type, { type: contentType, index: maxIndex + 1 });
  };

  return (
    <div className="AddForm">
      <h5><Trans i18nKey="learning:form_add_component_title" /></h5>
      <div
        className="AddForm__Type AddForm__Type--text"
        onClick={() => handleAdd(EComponentTypes.TEXT)}
      >
        <Icon type="subject" />
        <Trans i18nKey="learning:form_add_component_label_text" />
      </div>
      <div
        className="AddForm__Type AddForm__Type--video"
        onClick={() => handleAdd(EComponentTypes.VIDEO)}
      >
        <Icon type="local_movies" />
        <Trans i18nKey="learning:form_add_component_label_video" />
      </div>
      <div
        className="AddForm__Type AddForm__Type--youtube"
        onClick={() => handleAdd(EComponentTypes.EXTERNAL_VIDEO)}
      >
        <Icon type="play_circle__filled" />
        <Trans i18nKey="learning:form_add_component_label_youtube" />
      </div>
      <div
        className="AddForm__Type AddForm__Type--image"
        onClick={() => handleAdd(EComponentTypes.IMAGE)}
      >
        <Icon type="image__filled" />
        <Trans i18nKey="learning:form_add_component_label_image" />
      </div>
      <div
        className="AddForm__Type AddForm__Type--pdf"
        onClick={() => handleAdd(EComponentTypes.PDF)}
      >
        <Icon type="document__filled" />
        <Trans i18nKey="learning:form_add_component_label_pdf" />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  addComponentProp: addComponentAction,
};

export default connect(undefined, mapDispatchToProps)(AddComponentForm);
