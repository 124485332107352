import React, { useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Trans } from 'react-i18next';

import PureList from '@common/components/list';
import Icon from '@common/components/icon';
import ScreenComponent from './component';
import * as screenSelector from '../../selectors/screen';
import * as draftSelector from '../../selectors/draft';

import type { StoreState } from '@common/types/store';
import type { Screen, ScreenComponentItem } from '../../types/objects';

export const EScales = {
  small: 0.75,
  xsmall: 0.666666,
};

type OwnProps = {
  screenId: string;
  disableResolution: boolean;
  scrollRef: React.RefObject<HTMLDivElement>;
  resolution: { x: number; y: number };
  screens?: Screen[];
  size?: 'small' | 'xsmall';
  highlight?: string;
  onOpenComponent?: (component: ScreenComponentItem) => void;
  focusedScreenId?: string;
};

const mapStateToProps = (state: StoreState, props: OwnProps) => {
  let screen;
  if (props.screens) {
    screen = props.screens.find((s) => s.id === props.screenId) as Screen;
  } else {
    // Fallback on the redux draft state when no screens are provided
    // Should be removed in scope of ONE-807
    const itemSelector = screenSelector.item();
    const draft = draftSelector.screen(state, props.screenId);
    screen = (draft || itemSelector(state, props.screenId)) as Screen | null | undefined;
  }
  return { screen };
};

const reduxConnector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof reduxConnector>;

export const UnconnectedScreenPreview = ({
  scrollRef,
  screen,
  size,
  disableResolution,
  resolution = { x: 375, y: 667 },
  highlight,
  onOpenComponent,
  focusedScreenId,
}: Omit<Props, 'screenId'>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (screen?.id === focusedScreenId && scrollRef.current && containerRef.current) {
      scrollRef.current.scroll({ top: containerRef.current.offsetTop, behavior: 'smooth' });
    }
  }, [focusedScreenId, screen]);

  if (!screen?.id) return null;

  const scale = (size && EScales[size]) || 1;

  const classNames = ['Screen'];
  if (onOpenComponent) classNames.push('Screen--clickable');

  return (
    <div
      className={classNames.join(' ')}
      ref={containerRef}
      style={!disableResolution ? {
        width: resolution.x * scale,
        height: resolution.y * scale,
      } : undefined}
    >
      <div
        className="Screen__Inner"
        style={!disableResolution ? {
          width: resolution.x,
          height: resolution.y,
          marginLeft: -((resolution.x - (resolution.x * scale)) / 2),
          marginTop: -((resolution.y - (resolution.y * scale)) / 2),
          transform: `scale(${scale}, ${scale})`,
          WebkitTransform: `scale(${scale}, ${scale})`,
        } : undefined}
      >
        <div className="Screen__Content">
          <PureList
            items={screen.components}
            renderRow={ScreenComponent}
            rowProps={(item) => ({
              onOpenComponent,
              highlight: item.id === highlight,
            })}
            placeholder={onOpenComponent && (
              <div className="Screen__Placeholder">
                <Icon type="dashboard" size="large" />
                <b><Trans i18nKey="learning:screen_preview_placeholder_title" /></b>
                <div>
                  <Trans i18nKey="learning:screen_preview_placeholder_description" />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default reduxConnector(UnconnectedScreenPreview);
