import React from 'react';

type Props = {
  children: React.ReactNode;
  className?: string;
};

const BarComponent = ({ children, className }: Props) => (
  <div className={'Bar' + (className ? ` ${className}` : '')}>
    { children }
  </div>
);

export default BarComponent;
export const Bar = BarComponent;
