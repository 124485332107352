import * as R from 'ramda';
import fetchModule from './fetch-module';
import * as screensReducer from '../reducers/screens';

export const ACADEMY_CREATE_SCREEN_DRAFT = 'academy/CREATE_SCREEN_DRAFT';

export default (screenId, moduleId) => async (dispatch, getState) => {
  const { academy } = getState();
  if (academy.draft.screens[screenId]) return;

  if (!academy.screens.items[screenId]) {
    await dispatch(fetchModule(moduleId));
  }

  const screen = screensReducer.findById(getState(), screenId);

  if (!screen) throw new Error('no_screen_found');

  screen.component_ids = R.pluck('id', screen.components);
  screen.question_id = screen.question && screen.question.id;

  return dispatch({
    type: ACADEMY_CREATE_SCREEN_DRAFT,
    screen: R.omit(['components'], screen),
    components: screen.components,
  });
};
