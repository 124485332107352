import React, { memo, useCallback } from 'react';
import { PredicateInput } from '@common/components/predicates-filter';

const PurePredicateInput = memo(PredicateInput);

const PredicateFiltersGroup = memo(({
  predicates = [],
  setPredicates,
  organisation
}: any) => {
  const setPredicate = useCallback((predicate: any, index: number) => {
    const newPredicates = [...predicates];
    newPredicates[index].value = predicate;

    setPredicates(newPredicates);
  }, [setPredicates, predicates]);
  return (
    <>
      {
        predicates.map(({
          scrolling, value, ...props
        }: any, i: number) => {
          // predicate list ordering does not change, so it's safe to use
          // the index as key
          return <PurePredicateInput
            key={i.toString()}
            input={{
              value,
              onChange: (predicate: any) => setPredicate(predicate, i)
            }}
            buttonType="white"
            organisation={organisation}
            scrolling={
              typeof scrolling === 'boolean' ?
              scrolling :
              true
            }
            {...props}
          />;
        })
      }
    </>
  );
});

export default PredicateFiltersGroup;
