import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { UseControllerProps, useController } from 'react-hook-form';
import { omit } from 'lodash';

import { NumberInput, NumberInputOwnProps } from './number-input';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const NumberInputHookForm = (props: Omit<NumberInputOwnProps, 'ref'> & UseControllerProps) => {
  const { field, fieldState } = useController(props);
  return (
    <NumberInput
      {...props}
      {...field}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
};

const ReduxFormField = (props: WrappedFieldProps & NumberInputOwnProps) => {
  return (
    <NumberInput
      error={props.meta.touched && props.meta.error}
      warning={props.meta.touched && props.meta.warning}
      {...props.input}
      {...omit(props, ['input', 'meta'])}
    />
  );
};

const NumberInputField = ({ name, ...props }: BaseFieldProps & NumberInputOwnProps) => (
  <Field
    id={name}
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default NumberInputField;
