import * as validator from '../../../../common/utils/validation';
import { EComponentTypes } from '../../definitions';

export default (values) => {
  const errors = {};

  switch (values?.type) {
    case EComponentTypes.TEXT:
      if (escape(values.text) === '%u200B%0A') errors.text = true;
      break;
    case EComponentTypes.VIDEO:
      if (values.youtube_url && !validator.test('YOUTUBE_URL', values.youtube_url)) errors.youtube_url = true;

      if (values.youtube_url) break;
      // fallthrough
    case EComponentTypes.IMAGE:
      if (!values.attachment) errors.attachment = true;
      if (values.attachment) errors.attachment = values.attachment.processing;
      break;
    default:
      break;
  }

  return errors;
};
