import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';

import Spinner from '@common/components/spinner';
import { Icon } from '@common/components/icon';
import ScreenPreview from '../screen-preview';
import { Screen } from '@modules/learning/types/objects';

type Props = {
  screenIDs?: string[];
  screens?: Screen[];
  focusedScreenId?: string;
  resolution?: { x: number; y: number; };
  scale?: number;
  isLoading?: boolean;
  navbarTitle?: string | null;
  showBackButton?: boolean;
  progress?: {
    total: number;
    current: number;
  } | null;
};

const PhonePreview = ({
  resolution = { x: 375 * 0.85, y: 667 * 0.85 },
  scale = 1, // 0.85
  navbarTitle,
  progress,
  isLoading,
  showBackButton = true,
  screenIDs,
  focusedScreenId,
  ...props
}: Props) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <div className="Phone">
      <div className="Phone__Header">
        <div className="Phone__Sensor" />
        <div className="Phone__Speaker" />
      </div>

      <div
        className="Phone__Screen"
        style={{ width: resolution.x * scale, height: resolution.y * scale }}
      >
        <div
          className="Phone__Screen__Inner"
          style={{
            width: resolution.x,
            height: resolution.y,
            marginLeft: -((resolution.x - (resolution.x * scale)) / 2),
            marginTop: -((resolution.y - (resolution.y * scale)) / 2),
            transform: `scale(${scale}, ${scale})`,
            WebkitTransform: `scale(${scale}, ${scale})`,
          }}
        >
          <div className="Phone__Navbar">
            <div className="Phone__Navbar__Time">{moment().format('HH:mm')}</div>
            <div className="Phone__Navbar__Title">{navbarTitle}</div>
            <div className="Phone__Navbar__ProgressBar">
              {progress && (
                <div
                  className="Phone__Navbar__ProgressBar__Fill"
                  style={{ width: `${(progress.current / progress.total) * 100}%` }}
                />
              )}
            </div>
          </div>
          <div className="Phone__Screen__Content" ref={scrollRef}>
            {isLoading && (
              <div className="Phone__Screen__Spinner">
                <Spinner size="large" centered />
              </div>
            )}
            {screenIDs?.map((screenId) => (
              <ScreenPreview
                key={`screen-preview-${screenId}`}
                disableResolution={screenIDs.length > 1}
                screenId={screenId}
                scrollRef={scrollRef}
                focusedScreenId={focusedScreenId}
                resolution={{
                  x: resolution.x,
                  y: resolution.y - 94 - 80,
                }}
                {...props}
              />
            ))}
          </div>
          <div className="Phone__BottomBar">
            {showBackButton && progress && progress.current > 0 && (
              <div className="Phone__BottomBar__Button"><Icon type="arrow_back" /></div>
            )}
            <div className="Phone__BottomBar__Button Phone__BottomBar__Button--next">
              <Trans i18nKey="learning:phone_preview_bottom_bar_next" />
            </div>
          </div>
        </div>
      </div>

      <div className="Phone__Footer">
        <div className="Phone__HomeButton" />
      </div>
    </div>
  );
};

export default PhonePreview;
