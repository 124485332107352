import * as R from 'ramda';

export const ACADEMY_UPDATE_COMPONENTS_ORDER = 'academy/UPDATE_COMPONENTS_ORDER';

export default (screenId, order) => async (dispatch) => {
  const components = R.addIndex(R.map)((id, index) => ({ id, index }), order);

  return dispatch({
    type: ACADEMY_UPDATE_COMPONENTS_ORDER,
    screenId,
    items: components,
  });
};
