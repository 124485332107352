import * as R from 'ramda';
import Api from '../../../common/services/api';
import { EComponentTypes } from '../definitions';
import { ACADEMY_RECEIVE_MODULE } from './index';

export default (id) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const { data: module } = await Api
    .get(`/v2/organisations/${selected.id}/modules/${id}?include=components`);

  module.screen_ids = R.pluck('id', module.screens);
  module.screens = module.screens.map((screen) => { /* eslint-disable no-param-reassign */
    const question = R.find(R.propEq('type', EComponentTypes.ELEARNING_QUESTION), screen.components);

    screen.component_ids = R.pluck('id', screen.components);

    screen.question = question;
    screen.question_id = question ? question.id : null;

    return screen;
  });

  return dispatch({
    type: ACADEMY_RECEIVE_MODULE,
    item: module,
  });
};
