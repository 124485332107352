import React, { useMemo } from 'react';
import { UseControllerProps, useController } from 'react-hook-form';

import { regex } from '@common/utils/validation';
import { SplitTimePicker, SplitTimePickerOwnProps } from './split-time-picker-input';
import { omit } from 'lodash';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export const SplitTimePickerHookForm = (props: SplitTimePickerOwnProps & UseControllerProps) => {
  const updatedRules = useMemo(() => {
    if (props.rules?.required) {
      return {
        ...props.rules,
        pattern: (typeof props.rules.required === 'string') ?
        { value: regex.TIME, message: props.rules.required } :
        regex.TIME,
      };
    }
    return undefined;
  }, [props.rules]);
  const { field, fieldState } = useController({
    ...props,
    rules: updatedRules || props.rules,
  });
  return (
    <SplitTimePicker
      {...props}
      {...omit(field, ['ref'])}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
};
