import memoize from '../../../common/utils/memoize';
import * as draftReducer from '../reducers/draft';

const getScreen = (state, screenId) => state.academy.draft.screens[screenId];
const getScreenForm = (state) => state.form;
const getComponent = (state, item) => state.academy.draft.components[item.id];
const getScreenQuestion = (state, screenId) => {
  const screen = getScreen(state, screenId);

  if (!screen || !screen.question_id) return;

  return state.academy.draft.questions[screen.question_id];
};

export const screen = memoize.createSelector(
  [getScreen, getScreenForm, getScreenQuestion],
  (state, item) => item && draftReducer.getScreen(state, item.id),
);

export const screenItem = memoize.createItemSelector(
  [getScreen, getScreenForm, getScreenQuestion],
  (state, item) => item && draftReducer.getScreen(state, item.id),
);

export const component = memoize.createItemSelector(
  [getComponent],
  (state, item) => (item && draftReducer.getComponent(state, item.id)) || null,
);

export const componentFeedback = () => {
  return (state, questionId, item) => (item && questionId && draftReducer.getComponentFeedback(state, questionId, item)) || null;
};

export const question = memoize.createSelector(
  [getComponent],
  (state, item) => (item && draftReducer.getComponent(state, item.id)) || null,
);
