import React from 'react';
import { Icon } from '@common/components/icon';
import { fileTypesStyles } from '@common/definitions';

import type { Document } from '../../types';

type Props = {
  item: Document,
};

export const DocumentIcon = ({ item }: Props) => {
  if (item.type === 'personal_folder') {
    return (
      <div className="DocumentIcon DocumentIcon--personal">
        <Icon type="folder__filled" />
      </div>
    );
  }

  if (item.is_folder) {
    return (
      <div className="DocumentIcon DocumentIcon--folder">
        <Icon type="folder__filled" />
      </div>
    );
  }

  if ((item.attachment?.file_type === 'video' || item.attachment?.file_type === 'image') && item.attachment?.thumb_path) {
    return <div className="DocumentIcon DocumentIcon--thumb" style={{ backgroundImage: `url(${item.attachment.thumb_path})` }} />;
  }

  return (
    <img
      src={fileTypesStyles[item.attachment?.file_type || 'null'] || fileTypesStyles.null}
      alt={item.attachment?.file_type || 'Icon'}
      className="DocumentIcon"
    />
  );
};
