import * as React from 'react';
import * as R from 'ramda';
import { Trans } from 'react-i18next';
import Hint from '@common/components/hint';
import { combineClassNames } from '@common/utils/combineClassNames';
import BaseScreenComponent from './base-screen-component';

export const QuestionMultipleChoiceScreenComponent = ({
  item,
  value = [],
  onChange,
  outcomePhase = false,
  showCorrectAnswers = false
}) => {

  const allowsMultipleAnswers = (
    R.filter(R.propEq('correct', true), item.parameters.options).length > 1 ||
    // this is for surveys, different item structure
    item?.parameters?.allow_multiple_options
  );

  return (
    <BaseScreenComponent item={item} className="QuestionMultipleChoiceScreenComponent">
      {
        allowsMultipleAnswers && (
          <div className="Screen__Component__Question__Warning">
            <Trans i18nKey="learning:screen_preview_component_question_multiple_possibilities" />
          </div>
        )
      }
      <div className="Screen__Component__Question__Options">
        {
          item.parameters.options.map((option) => {
            const selected = value.indexOf(option.id) > -1;
            const showCorrect = outcomePhase && option.correct && (selected || showCorrectAnswers);
            const showWrong = outcomePhase && !option.correct && selected;

            const classes = combineClassNames('Screen__Component__QuestionOptions__Item', {
              selected: selected && !outcomePhase,
              readOnly: !onChange,
              'Screen__Component__QuestionOptions__Item--correct': showCorrect,
              'Screen__Component__QuestionOptions__Item--wrong': showWrong
            });

            const onClick = () => {
              if (!onChange) return;
              if (selected) {
                onChange(value.filter((id) => id !== option.id));
              } else {
                if (!allowsMultipleAnswers) {
                  return onChange([option.id]);
                }
                onChange([...value, option.id]);
              }
            };

            return (
              <button key={option.id} className={classes} onClick={onClick}>
                { option.text || option.value }
              </button>
            );
          })
        }
      </div>
      {
        item.parameters.hint && (
          <div className="Screen__Component__Question__Hint">
            <Hint text={item.parameters.hint} />
          </div>
        )
      }
    </BaseScreenComponent>
  );
};
