import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';
import Icon from '@common/components/icon';
import ComponentForm from '../../forms/component';
import { EComponentTypes } from '../../definitions';

const getComponentConfig = (type, t) => {
  switch (type) {
    case EComponentTypes.TEXT:
      return {
        icon: 'subject',
        label: t('learning:components_list_component_label_text'),
        color: 'green'
      };
    case EComponentTypes.IMAGE:
      return {
        icon: 'image__filled',
        label: t('learning:components_list_component_label_image'),
        color: 'purple',
      };
    case EComponentTypes.VIDEO:
      return {
        icon: 'local_movies',
        label: t('learning:components_list_component_label_video'),
        color: 'yellow',
      };
    case EComponentTypes.EXTERNAL_VIDEO:
      return {
        icon: 'play_circle__filled',
        label: t('learning:components_list_component_label_youtube'),
        color: 'youtube',
      };
    case EComponentTypes.PDF:
      return {
        icon: 'document__filled',
        label: t('learning:components_list_component_label_pdf'),
        color: 'orange',
      };
    default:
      return null;
  }
};

const LearningComponentItem = ({
  id, questionId, selected, disabled, item, onSelect, onDelete, onDrag, updateComponent,
}) => {
  const { t } = useTranslation();
  const rootRef = React.useRef(null);

  const [, dropRef] = useDrop({
    accept: ['component'],
    hover: (source) => {
      if (source.id === item.id) return;

      onDrag(source.id, item.index);
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'component',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const type = getComponentConfig(item.type, t);

  if (!type) return null;

  const className = ['ComponentList__Item'];
  if (type) className.push(`ComponentList__Item--color-${type.color}`);
  if (selected) className.push('ComponentList__Item--open');

  return (
    <div
      ref={dropRef(previewRef(rootRef))}
      className={className.join(' ')}
      style={{ opacity: isDragging ? 0 : 1 }}
      onClick={!selected ? () => onSelect(item.id) : undefined}
    >
      {!selected && (
        <div className="ComponentList__Item__Row">
          <div className="ComponentList__Item__Handler" ref={dragRef}>
            <Icon type="drag_handle" />
          </div>
          <Icon type={type.icon} />
          {type.label || item.type}
          <div className="ComponentList__Item__Edit">
            <small><Trans i18nKey="learning:components_list_click_to_edit" /></small>
          </div>
        </div>
      )}
      {selected && (
        <ComponentForm
          form={`component-${item.id}`}
          questionId={questionId}
          screenId={id}
          item={item}
          disabled={disabled}
          updateComponent={updateComponent}
          onClose={() => onSelect()}
          onDelete={() => onDelete(item.id)}
        >
          <div className="ComponentList__Item__Row">
            <Icon type={type.icon} />
            {type.label || item.type}
          </div>
        </ComponentForm>
      )}
    </div>
  );
};

export default LearningComponentItem;
