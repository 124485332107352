import * as React from 'react';
import * as R from 'ramda';
import PureList from '../../../../common/components/list';
import Item from './item';

class ComponentsList extends React.Component {
  constructor() {
    super();

    this.state = {
      selected: null,
    };

    this.handleEditComponent = this.handleEditComponent.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const newComponents = R.differenceWith((a, b) => a.id === b.id, nextProps.items, this.props.items);

    if (newComponents.length === 1) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ selected: newComponents[0].id });
    }
  }

  static props;

  handleEditComponent(id) {
    this.setState({ selected: id });
  }

  handleSelect(id) {
    this.setState({ selected: id || null });
  }

  render() {
    const { items, ...props } = this.props;

    return (
      <PureList
        containerClassName="ComponentsList"
        items={R.sortBy(R.prop('index'), items)}
        renderRow={Item}
        rowProps={(item) => ({
          ...props,
          selected: this.state.selected === item.id,
          onSelect: this.handleSelect,
        })}
      />
    );
  }
}

export default ComponentsList;
