import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { combineClassNames } from '@common/utils/combineClassNames';
import { Message } from '../../message';

export type NumberInputOwnProps = Omit<JSX.IntrinsicElements['input'], 'onChange' | 'onBlur' | 'onError'> & {
  placeholder?: string;
  allowDecimals?: boolean;
  canBeEmpty?: boolean;
  canPaste?: boolean;
  warning?: boolean | string;
  error?: boolean | string;
  onChange?: (n: string) => void;
  onWarning?: (w: string) => void;
  onError?: (e: string) => void;
  onBlur?: (e: any) => void;
  ref?: React.MutableRefObject<HTMLInputElement | undefined>,
};

const MAX_NUMBERS = 16;

const PASTE_WITH_VALUE_WARNING = 'common:validation_number_decimal_not_allowed_pasting';
const MAX_VALUE_WARNING = 'common:validation_number_max';

const getNumbersFromStr = (str: string) => str.replace(/\D/g, '');
const strHasDecimal = (str: string) => str.indexOf(',') !== -1 || str.indexOf('.') !== -1;
const strHasMinus = (str: string) => str.indexOf('-') !== -1;
const canBeMinus = (str: string, start:number | null, min?: string | number) => {
  return str.indexOf('-') === -1 && start === 0 && (!min || +min < 0) && min !== 0;
};
const insertStringAtIndex = (str: string, index: number = 0, strToInsert: string) => {
  return [str.slice(0, index), strToInsert, str.slice(index)].join('');
};

export const NumberInput = React.forwardRef<HTMLInputElement | undefined, NumberInputOwnProps>(({
  value,
  placeholder,
  allowDecimals = false,
  canPaste = true,
  canBeEmpty = true,
  min,
  max = Number.MAX_SAFE_INTEGER,
  warning,
  error,
  onChange,
  onWarning,
  onError,
  onBlur,
  ...otherProps
}, ref) => {
  const [localValue, setLocalValue] = useState<JSX.IntrinsicElements['input']['value']>(value);
  const [localWarning, setWarning] = useState<string>('');
  const fullClassName = combineClassNames('Form__control', {
    'Form__control--invalid': !warning && !localWarning && !!error,
    'Form__control--warning': !!warning || !!localWarning,
  });

  const validate = useCallback((target: HTMLInputElement, input: string = '', paste = false): string | null => {
    const selection = window.getSelection()?.toString() || '';
    const { selectionStart: start } = target;
    const currentValue = target.value.replace(selection, '');
    const hasDecimal = strHasDecimal(currentValue);
    const inputHasDecimal = strHasDecimal(input);
    if (strHasMinus(input) && !canBeMinus(currentValue, start, min)) {
      if (strHasMinus(currentValue) || (start !== 0 && +min! < 0)) {
        // Only 1 minus sign is allowed at the start of the number
        return 'common:validation_number_minus_sign';
      }
      return 'common:validation_number_negative_not_allowed';
    }
    if (allowDecimals && !hasDecimal && !/^[-0-9.,]+$/.test(input)) {
      return 'common:validation_number_invalid';
    }
    if ((!allowDecimals || hasDecimal) && !/^[-0-9]+$/.test(input)) {
      if (hasDecimal && inputHasDecimal) {
        // Only 1 decimal sign is allowed
        return 'common:validation_number_decimal_sign';
      }
      if (inputHasDecimal) {
        if (paste && !currentValue) {
          // Decimal numbers are not allowed,
          // pasted value will be converted to a round number
          return PASTE_WITH_VALUE_WARNING;
        }
        return 'common:validation_number_decimal_not_allowed';
      }
      return 'common:validation_number_invalid';
    }
    const newValue = insertStringAtIndex(currentValue, start || 0, input);
    const onlyNumbers = getNumbersFromStr(newValue);
    if (onlyNumbers.length > MAX_NUMBERS) {
      // Maximum amount of numbers reached
      return 'common:validation_number_max_amount';
    }
    if (onlyNumbers.length === MAX_NUMBERS && inputHasDecimal) {
      if (start === 0 || !start || start + 1 === newValue.length) {
        // Cannot add decimal at the start or end of a number when the maximum amount of numbers is reached,
        // because it add an extra number (0) to the total amount of numbers.
        return 'common:validation_number_max_amount_decimal';
      }
    }
    const n = parseFloat(newValue.replace(',', '.'));
    if (min && n < +min) {
      return 'common:validation_number_min';
    }
    if (n > +max) {
      return MAX_VALUE_WARNING;
    }
    return null;
  }, [allowDecimals, min, max]);

  useEffect(() => {
    if (onWarning) onWarning(localWarning);
  }, [localWarning, onWarning]);

  useEffect(() => {
    if (onError && (typeof error === 'string' || !error)) {
      onError(error || '');
    }
  }, [error]);

  useEffect(() => setLocalValue(value), [value, setLocalValue]);

  return (
    <>
      <input
        {...otherProps}
        ref={ref as React.ForwardedRef<HTMLInputElement>}
        className={fullClassName}
        type="text"
        value={localValue}
        disabled={!onChange || otherProps.disabled}
        placeholder={placeholder}
        onPaste={(e) => {
          if (!canPaste) e.preventDefault();
          const target = e.target as HTMLInputElement;
          const input = (e.clipboardData || window.Clipboard).getData('text').trim();
          const validationWarning = validate(target, input, true);
          if (validationWarning) {
            e.preventDefault();
            setWarning(validationWarning);
            if (validationWarning === PASTE_WITH_VALUE_WARNING) {
              setLocalValue(parseInt(input));
              if (onChange) onChange(`${parseInt(input)}`);
            }
          }
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter') return;
          const target = e.target as HTMLInputElement;
          const validationWarning = validate(target, e.key);
          if (validationWarning) {
            e.preventDefault();
            setWarning(validationWarning);
          }
        }}
        onBlur={(...args) => {
          if (onChange && !canBeEmpty) {
            if (localValue === '') {
              onChange('0');
              setLocalValue('0');
            } else {
              setLocalValue(value);
            }
          }
          setWarning('');
          if (onBlur) onBlur(...args);
        }}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            setLocalValue(event.target.value);
            setWarning('');
            if (canBeEmpty || event.target.value !== '') {
              onChange(event.target.value);
            }
          }
        }}
      />
      <Message
        error={!onError && error}
        warning={warning || (!onWarning && localWarning)}
        max={max}
        min={min}
      />
    </>
  );
});
