import React from 'react';

import './simple-text.scss';

type SimpleTextProps = {
  text: string;
  className?: string;
};

const SimpleText = ({ text, className }: SimpleTextProps) => {
  return (
    <div className={`SimpleText ${className || ''}`}>
      {
        text.split('\n').map((line, index) => {
          if (!line) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span className="SimpleText__Linebreak" key={index}>
                <br />
              </span>
            );
          }
          // eslint-disable-next-line react/no-array-index-key
          return <p key={index}>{line}</p>;
        })
      }
    </div>
  );
};

export default SimpleText;

