import * as React from 'react';
import Attachment from '@common/components/attachment';
import Icon from '@common/components/icon';

const ComponentVideo = ({ item }) => {
  if (!item.parameters || !item.parameters.attachment) return null;

  return (
    <div className="Screen__Component__Video">
      <div className="Screen__Component__Video__Overlay">
        <Icon type="play_arrow" />
      </div>
      <Attachment trigger item={item.parameters.attachment} maxHeight={250} />
    </div>
  );
};

export default ComponentVideo;
